
/*=========================
# Event badge container
==========================*/
.event-badge-generator-container{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}
.content-of-event-badge-generator-container{
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}
/*=========================
# Event Form
==========================*/
.event-badge-form-container{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 5;
}
.content-of-event-logo-box-of-event-badge-form-container{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form-box{
    min-width: 400px;
}
.content-of-event-badge-form-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.event-logo-box-of-event-badge-form-container img{
    width: 350px;
}
.registration-number-container{
    margin-top: 35px;
    border: 2px solid;
    border-radius: 5px;
    width: 100%;
    height: 50px;
}
.label-of-registration-number-container {
    position: absolute;
    padding: 5px;
    font-size: 12px;
    letter-spacing: 0.5px;
    margin: -14px 10px 0;
}
.label-of-registration-number-container h3{
    margin: 0;
    font-weight: 500;
}
.value-of-registration-number-container h3{
    margin: 0;
    line-height: 50px;
    padding-left: 13px;
}
.keyboard-container{
    margin-top: 10px;
    border: 2px solid;
    border-radius: 5px;
    width: 100%;
}
.content-of-keyboard-container{
    position: relative;
    padding: 15px;
}
.single-keyboard-button {
    margin: 2px;
    height: 100px;
    border-radius: 5px;
    border: 2px solid;
    font-size: 24px;
    font-weight: 500;
    transition: all 0.35s ease-in-out;
}
.single-keyboard-button:hover,
.single-keyboard-button:focus{
    opacity: 0.5;
    cursor: pointer;
}
.content-of-single-keyboard-button {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.delete-symbold-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50px;
    cursor: pointer;
}
.content-of-registration-number-container{
    position: relative;
    width: 100%;
    height: 100%;
}
.content-of-delete-symbold-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
/*=========================
# Badge modal container
==========================*/
.badge-modal-container.active{
    margin-top: 0;
    visibility: visible;
}
.badge-modal-container{
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    margin-top: 100%;
    visibility: hidden;
    transition: all 1s ease-in-out;
}
.content-of-badge-modal-container{
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
}
.content-of-badge-template-container{
    position: relative;
    width: 100%;
    height: 100%;
}
.badge-template-container{
    width: 450px;
}
.badge-html-template-container{
    position: absolute;
    top: -1000px;
}
.badge-img-container{
    width: 450;
    position: relative;
}
.badge-img-container img{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 3;
}
.text-box-of-badge-template-container {
    position: absolute;
    /* top: 331px;
    left: 32px; */
    z-index: 5;
}
.text-box-of-badge-template-container h3{
    margin: 0;
    font-size: 34px;
}
.content-of-badge-img-container {
    position: relative;
    width: 100%;
    height: 100%;
}
.background-of-badge-img-container {
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
.hello-message-container h1 {
    margin: 0;
}
.hello-message-container {
    padding: 30px 0;
}
.qr-code-container {
    position: absolute;
    z-index: 5;
    /* width: 100%;
    height: 100%; */
    /* background-color: white; */
}
.buttons-of-badge-modal-container{
    padding-top: 15px;
}
.buttons-of-badge-modal-container button{
    margin-right: 5px;
}
.badge-to-print{
    display: none;
}
.badge-to-print img{
    width: 100%;
    height: 100%;
}
.loading-img-container {
    position: relative;
    width: 100%;
    height: 100%;
}
.badge-as-image-container {
    position: relative;
    width: 450px;
}
.content-of-loading-img-container {
    position: relative;
    width: 100%;
    height: 100%;
}
.loader-of-loading-img-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
.content-of-loader-of-loading-img-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader-of-loading-img-container .MuiCircularProgress-root {
    color: #00000030;
}
/*=========================
# Preloader
==========================*/
.preloader-container.active{
    visibility: visible;
    opacity: 0.9;
}
.preloader-container{
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.35s ease-in-out;
}
.content-of-preloader-container{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
/*=========================
# Editor
==========================*/
.event-badge-editor-container{
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    margin-bottom: -100%;
    transition: all 0.45s ease-in-out;
}
.event-badge-editor-container.active {
    visibility: visible;
    margin-bottom: 0;
}
.content-of-event-badge-editor-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.editor-sidebar-of-event-badge-editor-container{
    position: fixed;
    width: 20%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: white;
    z-index: 99999;
}
.content-of-editor-sidebar-of-event-badge-editor-container{
    overflow-x: scroll;
    position: relative;
    width: 100%;
    height: 100%;
}
.editable-content-of-event-badge-editor-container{
    position: fixed;
    width: 80%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
}
.content-of-editable-content-of-event-badge-editor-container {
    position: relative;
    width: 100%;
    height: 100%;
}
.no-touch-element{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;
}
.color-picture-box{
    position: relative;
}
.color-picture-box h3{
    margin: 0 0 10px;
}
.color-picture-box{
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.151);
}
.uploaded-img-container{
    position: relative;
    width: 100%;
    height: 100%;
}
.content-of-uploaded-img-container{
    position: relative;
    width: 100%;
    height: 100%;
}
.mask-of-uploaded-img-container{
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.486);
    opacity: 0;
    visibility: hidden;
    transition: all 0.35s ease-in-out;
}
.content-of-mask-of-uploaded-img-container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    color: white;
}
.content-of-mask-of-uploaded-img-container svg{
    color: white !important;
}
.uploaded-img-container:hover .mask-of-uploaded-img-container, .uploaded-img-container:focus .mask-of-uploaded-img-container{
    opacity: 1;
    visibility: visible;
}
.uploaded-img-container img{
    width: 100%;
}
.image-uploader-container{
    margin-bottom: 15px;
}
.editable-badge-container{
    position: relative;
    width: 100%;
    height: 100%;
}
.content-of-editable-badge-container-editable-badge-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.editable-badge-container .badge-html-template-container{
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
}
/*=========================
# Background Video
==========================*/
.bg-event-video-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
/*=========================
# Event Editor Styles
==========================*/
.badge-editor-modal-container-form-element {
    position: fixed;
    z-index: 9999;
    background-color: white;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    visibility: visible;
    transition: all 0.35s ease-in-out;
}
.badge-editor-sidebar-container{
    position: fixed;
    width: 20%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
}
.main-content-of-badge-editor-modal-container-form-element{
    position: fixed;
    width: 80%;
    right: 0;
    top: 0;
    bottom: 0;
}
.content-of-main-content-of-badge-editor-modal-container-form-element {
    position: relative;
    width: 100%;
    height: 100%;
}
.main-content-of-badge-editor-modal-container-form-element .event-badge-generator-container {
    position: relative;
    height: 100vh;
}
.main-content-of-badge-editor-modal-container-form-element .bg-event-video-container{
    position: absolute;
    height: 100vh;
}
/*=========================
# 
==========================*/

/*=========================
# 
==========================*/

/*=========================
# Print
==========================*/
/* @page {
    size: A4;
    margin: 0;
} */
/* @page {
    size: 80mm 140mm;
    margin: 0;
} */
@media print {
    * {
        -webkit-print-color-adjust: exact;
    }
    html, body {
        overflow: hidden;
        overflow-x: hidden;
        overflow-y: hidden;
        page-break-after: always;
    }
    /* html, body {
        width: 80mm;
        height: 140mm;
    } */
    .badge-to-print{
        display: block;
    }
    .badge-template-container{
        display: none;
    }
    .badge-html-template-container{
        display: none;
        position: relative;
    }
    .hello-message-container {
        display: none;
    }
    .buttons-of-badge-modal-container{
        display: none;
    }
    .badge-as-image-container{
        display: none;
    }
    .content-of-badge-modal-container {
        display: block;
    }
    .event-badge-generator-container {
        position: relative;
    }
    ::-webkit-scrollbar {
        width: 0;
    }
    .preloader-container{
        display: none;
    }
    .event-badge-form-container {
        display: none;
    }
}