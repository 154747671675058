/*===============================
# Sidebar
=================================*/
.admin-panel-sidebar {
    position: fixed;
    background-color: white;
    width: 15%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-right: 1px solid #00000017;
    z-index: 999;
    visibility: visible;
    transition: all 0.45s ease-in-out;
}
.content-of-admin-panel-sidebar {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}
.mask-of-admin-panel-sidebar{
  position: fixed;
  background-color: black;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 990;
  opacity: 0;
  visibility: hidden;
  transition: all 0.45s ease-in-out;
}
/*===============================
# Main Content
=================================*/
.admin-panel-main-content {
    position: relative;
    height: 100vh;
    padding-left: 18%;
    padding-right: 4%;
    transition: all 0.45s ease-in-out;
}
.content-of-admin-panel-main-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 35px;
    padding-bottom: 35px;
}
.admin-section-main-wrapper {
    padding-bottom: 50px;
}
/*===============================
# Sections
=================================*/
.admin-panel-head-section h1{
    margin: 0;
}
.admin-panel-head-section{
    padding-bottom: 15px;
}
.admin-panel-single-section-container{
    border: 1px solid #00000017;
    border-radius: 5px;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 15px;
}
.content-of-admin-panel-single-section-container{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 35px;
}
/*===============================
# Admin Menu
=================================*/
.app-logo-of-admin-panel-sidebar img{
  width: 200px;
}
.app-logo-of-admin-panel-sidebar{
  padding-top: 35px;
}
.content-of-app-logo-of-admin-panel-sidebar{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-panel-menu-navigation{
  padding: 20px 15px 0;
}
.content-of-admin-panel-menu-navigation {
  position: relative;
  width: 100%;
  height: 100%;
}
.admin-panel-menu-navigation a {
  text-decoration: none;
}
.single-admin-panel-menu-item.active {
  background-color: #f05e2a;
  color: white;
}
.single-admin-panel-menu-item.active a {
  color: white
}
.single-admin-panel-menu-item {
  height: 50px;
  border-radius: 5px;
  transition: all 0.35s ease-in-out;
}
.content-of-single-admin-panel-menu-item {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px 0;
}
.text-box-of-single-admin-panel-menu-item h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
}
.text-box-of-single-admin-panel-menu-item {
  padding-left: 15px;
}
.single-admin-panel-menu-item a {
  color: #00000094;
}
.single-admin-panel-menu-item{
  margin-bottom: 5px;
}
.single-admin-panel-menu-item:hover, .single-admin-panel-menu-item:focus{
  background-color: #00000017;
}
.single-admin-panel-menu-item.active:hover, .single-admin-panel-menu-item.active:focus{
  background-color: #d75121;
}
.hide-sidebar-box {
padding: 51px 20px 0;
}
.admin-panel-base-page-container.hide-sidebar .admin-panel-sidebar {
margin-left: -15%;
}
.admin-panel-base-page-container.hide-sidebar .admin-panel-main-content {
max-width: 100%;
padding-left: 1.5%;
padding-right: 1.5%;
/* overflow-x: hidden;
overflow-y: hidden; */
}
.admin-header-container {
position: relative;
background-color: white;
width: 100%;
height: 120px;
border-bottom: 1px solid #00000017;
}
.menu-container-of-admin-header-container .content-of-admin-panel-menu-navigation {
display: flex;
justify-content: flex-end;
}
.menu-container-of-admin-header-container .single-admin-panel-menu-item {
margin-bottom: 0;
margin-right: 5px;
}
.menu-container-of-admin-header-container {
position: relative;
padding: 6px 0 0;
}
.content-of-admin-header-container {
padding: 0 1.5%;
position: relative;
height: 100%;
}
.content-of-logo-box-of-admin-header-container {
position: relative;
display: flex;
justify-content: flex-start;
align-items: center;
height: 100%;
}
.logo-box-of-admin-header-container img{
max-width: 150px;
}
.logo-box-of-admin-header-container {
position: relative;
width: 100%;
height: 100%;
}
.admin-header-container .MuiGrid-container {
position: relative;
width: 100%;
height: 100%;
}
.logo-box-of-admin-header-container .hide-sidebar-box {
padding: 10px 0 0 10px;
}
.admin-header-container{
transition: all 0.45s ease-in-out;
margin-top: -123px;
}
.admin-panel-base-page-container.hide-sidebar .admin-header-container{
margin-top: 0;
}
/*=========================
# User Info box sidebar
==========================*/
/* .user-information-box-side-bar-container{
    position: absolute;
    width: 100%;
    bottom: 0;
} */
.content-of-user-information-box-side-bar-container{
    padding: 25px 25px 0;
}
.user-info-box-sidebar{
    position: relative;
    border-radius: 15px;
    background-color: #00000014;
}
.content-of-user-info-box-sidebar {
    position: relative;
    display: flex;
    padding: 15px;
    align-items: center;
    flex-direction: row;
}
.text-box-of-user-info-box-sidebar h3{
    margin: 0;
    font-weight: 500;
    font-size: 15px;
}
.text-box-of-user-info-box-sidebar{
    padding-left: 10px;
}
.text-box-of-user-info-box-sidebar a{
    font-size: 12px;
    padding-right: 10px;
    text-decoration: none;
    margin: 0;
    color: black;
    opacity: .5;
    transition:  all 0.35s ease-in-out;
}
.text-box-of-user-info-box-sidebar a:hover,
.text-box-of-user-info-box-sidebar a:focus{
    opacity: .8;
}
/*=========================
# Warehouse single item page
==========================*/
.warehouse-item-header {
    /* position: fixed; */
    z-index: 999;
    width: 100%;
    height: 70px;
    top: 0;
    right: 0;
    left: 0;
    background-color: white;
    /* border-bottom: 1px solid #0000001c; */
    margin-top: -20px;
  }
  .content-of-warehouse-item-header {
    position: relative;
    height: 100%;
    padding: 0 35px 0;
  }
  .warehouse-item-header .MuiGrid-root.MuiGrid-container {
    max-height: 100%;
    margin-top: 0;
  }
  .warehouse-item-header .MuiGrid-grid-xs-1{
    max-width: 4.333333%;
    flex-basis: 4.333333%;
  }
  .page-title-of-back-icon-of-warehouse-item-header h1{
    margin: 0;
    line-height: 70px;
    padding: 0;
    font-size: 25px;
  }
  .back-icon-of-warehouse-item-header {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .content-of-back-icon-of-warehouse-item-header {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .single-warehouse-item-page-section-container{
    /* padding-top: 30px; */
  }
  .content-of-single-warehouse-item-page-section-container{
    padding: 35px 35px;
  }
  .content-of-single-warehouse-item-stickers-container{
    padding: 35px;
  }
  .single-warehouse-item-stickers-container {
    border-radius: 5px;
    border: 1px solid #0000001c;
    margin-bottom: 35px;
  }
  .single-warehouse-item-stickers-container:last-child{
    margin-bottom: 0;
  }
  .miraj-logo-container-of-single-item-sticker-container img{
    width: 200px;
  }
  .single-item-sticker-container{
    max-width: 500px;
    position: relative;
  }
  .content-of-single-item-sticker-container {
    padding: 35px;
  }
  .single-item-sticker-container.style-02 .content-of-single-item-sticker-container {
    padding: 15px;
  }
  .single-item-sticker-container.style-03 .content-of-single-item-sticker-container {
    padding: 15px;
  }
  .single-item-sticker-container {
    max-width: 700px;
    border: 1px dashed #00000047;
  }
  .content-of-warehouse-item-details-single-box {
    display: flex;
    flex-direction: row;
  }
  .title-section-of-warehouse-item-details-single-box {
    width: 22%;
    background-color: black;
    color: white;
    /* height: 100%; */
  }
  .content-of-value-section-of-warehouse-item-details-single-box {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
}
  .title-section-of-warehouse-item-details-single-box h3{
    font-weight: 500;
    padding: 0 5px 0;
    margin: 0;
    line-height: 50px;
  }
  .value-section-of-warehouse-item-details-single-box h3{
    padding: 0 5px 0;
    margin: 0;
    line-height: 25px;
  }
  .value-section-of-warehouse-item-details-single-box {
    width: 70%;
  }
  .warehouse-item-details-single-box {
    border-radius: 5px;
    border: 2px solid black;
    margin-bottom: 5px;
    min-height: 50px;
  }
  .content-of-warehouse-item-details-single-box{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .single-item-details-boxes-container {
    padding: 15px 0 0;
  }
  .title-box-of-single-warehouse-item-stickers-container h3{
    margin: 0 0 0;
    font-size: 20px;
  }
  .all-stickers-of-the-item-container{
    padding-top: 50px;
  }
  .save-stickers-into-images-container{
    padding-top: 15px;
  }
  .content-of-title-section-of-warehouse-item-details-single-box{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .miraj-logo-in-the-right-corner{
    position: absolute;
    right: 15px;
    bottom: 15px;
  }
  .miraj-logo-in-the-right-corner img{
    width: 120px;
  }
  .some-information-about-the-warehouse-item h3{
    margin: 0 0 5px;
  }
  .some-information-about-the-warehouse-item{
    padding: 10px 15px 0;
  }
  .single-item-sticker-container.style-03 .some-information-about-the-warehouse-item h3{
    font-size: 14px;
  }
  .single-item-sticker-container.style-03 .miraj-logo-in-the-right-corner img{
    width: 90px;
  }
  .single-item-sticker-container.style-03 {
    max-width: 500px;
  }
  .single-item-thumbnail-container img{
    max-width: 500px;
    max-height: 500px;
  }
  .item-rental-button-container{
    padding-top: 15px;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .content-of-item-rental-button-container{
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
  }
  .warehouse-floating-button-container{
    position: fixed;
    z-index: 999;
    bottom: 10px;
    right: 10px;
  }
  .content-of-single-items-request-page-container{
    padding: 35px;
  }
  .single-items-request-details-container h1{
    margin: 0;
  }
  .single-items-request-details-container p{
    margin: 10 0 0;
  }
  .requested-items-container-for-data-displayer{
    padding-top: 30px;
  }
  .title-of-requested-items-container-for-data-displayer{
    margin: 0 0 15px;
  }
  /* .single-items-request-details-container{
    border: 1px solid #0000001a;
    border-radius: 15px;
  }
  .content-of-single-items-request-details-container{
    padding: 30px;
  } */
  .single-items-request-details-container .single-item-details-boxes-container{
    padding: 0;
  }
  .warehouse-item-details-single-box.multiline {
    height: auto;
  }
  .warehouse-item-details-single-box.multiline .title-section-of-warehouse-item-details-single-box{
    position: absolute;
  }
  .warehouse-item-details-single-box.multiline .value-section-of-warehouse-item-details-single-box{
    padding-left: 20%;
  }
  .warehouse-item-details-single-box.multiline  .value-section-of-warehouse-item-details-single-box h3{
    line-height: 30px;
    padding: 10px 5px;
  }
  .warehouse-item-details-single-box.pending .value-section-of-warehouse-item-details-single-box h3 {
    color: #ff6b00;
  }
  .warehouse-item-details-single-box.declined .value-section-of-warehouse-item-details-single-box h3 {
    color: #ff0000;
  }
  .warehouse-item-details-single-box.approved .value-section-of-warehouse-item-details-single-box h3 {
    color: green;
  }
  .warehouse-item-details-single-box.waiting .value-section-of-warehouse-item-details-single-box h3 {
    color: #0088ff;
  }
  .single-request-action-buttons-container{
    padding-top: 15px;
  }
  .single-request-action-buttons-container button{
    margin-right: 5px;
  }
  .buttons-container-of-nothing-in-the-cart-container button{
    margin-right: 5px;
  }
  .nothing-in-the-cart-container svg{
    font-size: 54px;
    color: #0088ff;
    text-align: center;
    margin: 0 auto;
  }
  .nothing-in-the-cart-container h1{
    margin: 10 0 0;
    text-align: center;
  }
  .nothing-in-the-cart-container p{
    margin: 0 0 0;
  }
  .nothing-in-the-cart-container {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .warehouse-cart-page-container{
    position: relative;
    height: 100%;
    width: 100%;
    margin-top: -100px;
  }
  .content-of-nothing-in-the-cart-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  .buttons-container-of-nothing-in-the-cart-container {
    padding-top: 30px;
  }
/*===============================
# IMage stickers
=================================*/
.img6-5cm-container-of-stickers-page-item img {
    width: 6.5cm;
}
.img7cm-container-of-stickers-page-item img {
    width: 7cm;
}
.stickers-page-item {
    width: 21cm;
    height: 29.7cm;
    background-color: white;
    /* visibility: hidden; */
}
.container-of-stickers-page-item{
    position: fixed;
    margin-top: -10000px;
}
.content-of-stickers-page-item {
    padding: 0.5cm;
}
/*===============================
# Item Details Action Buttons
=================================*/
.item-details-action-buttons-container {
  padding-top: 15px;
}
.item-details-action-buttons-container button {
  margin-right: 5px;
}
.select-request-form-modal-container{
  padding-top: 10px;
}
/*===============================
# Item Request Single Page Details
=================================*/
.single-item-requests-details-page-container{
  padding-top: 15px;
}
.requested-items-container{
  padding-top: 30px;
}
.requested-items-list-container-like-data-displayer-box{
  padding-top: 15px;
}
.load-more-items-button-container {
  padding-top: 15px;
}
.content-of-load-more-items-button-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.load-more-items-button-container button {
  border-radius: 50px;
}
.single-data-displayer-item-buttom button .MuiCircularProgress-root {
  width: 20px !important;
  height: 20px !important;
  color: #757575;
}
.skeletons-containers{
  padding-top: 35px;
}
.skeletons-containers .MuiSkeleton-root {
  height: 120px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.action-buttons-of-single-item-request-details-container-box button {
  margin-right: 5px;
  box-shadow: none;
}
.action-buttons-of-single-item-request-details-container-box{
  padding-top: 15px;
}
.decline-modal-custom-content{
  padding-top: 10px;
}
/*===============================
# Select Item Modal
=================================*/
.select-item-modal-container-content .add-new-item-bottom-container {
  display: none;
}
/*===============================
# Item Transfer Requests
=================================*/
.item-transfer-requests-action-buttons-container button{
  margin-right: 5px;
}
.span-button-status {
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #e8e8e8;
  color: #727272;
  padding: 5px 5px;
  border-radius: 5px;
}
.span-button-status.declined {
  background-color: red;
  color: white;
}
.span-button-status.pending {
  background-color: orange;
  color: white;
}
.span-button-status.waiting {
  background-color: #00b3b3;
  color: white;
}
.span-button-status.taken,
.span-button-status.ontheway {
  background-color: #0093ff;
  color: white;
}
.span-button-status.active,
.span-button-status.approved, 
.span-button-status.received {
  background-color: #00b600;
  color: white;
}
.info-alert-mes-box{
  padding-bottom: 15px;
}
/*===============================
# Statistics
=================================*/
.single-general-statistics-numbers-box {
  border: 1px solid #00000017;
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 5px;
  margin-right: 10px;
}
.single-general-statistics-numbers-box:last-child{
  margin-right: 0;
}
.general-statistics-numbers-container {
  padding-top: 20px;
}
.content-of-general-statistics-numbers-container {
  position: relative;
  display: flex;
}
.content-of-single-general-statistics-numbers-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 35px;
}
.text-box-of-single-general-statistics-numbers-box h3{
  margin: 0;
  font-weight: 400;
}
.text-box-of-single-general-statistics-numbers-box h4{
  margin: 0;
}
.text-box-of-single-general-statistics-numbers-box {
  padding: 3px 0 0 12px;
}
.icon-box-of-single-general-statistics-numbers-box {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #0000000f;
  color: #00000080;
}
.content-of-icon-box-of-single-general-statistics-numbers-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*===============================
# Stickers
=================================*/
.all-the-stickers-global-container {
  position: fixed;
  margin-top: -10000px;
}
.all-the-stickers-global-container{
  width: 21cm;
  height: 29.7cm;
  background-color: white;
}
.single-sticker-img-container .MuiSkeleton-root {
  max-width: 650px;
}
.single-sticker-img-container img {
  width: 100%;
  max-width: 650px;
}
/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
# Mobile Header
=================================*/
.mobile-sidebar-container.active{
  margin-left: 0;
  visibility: visible;
}
.mask-of-mobile-sidebar-container.active{
  opacity: .7;
  visibility: visible;
}
.mask-of-mobile-sidebar-container {
  position: fixed;
  background-color: black;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.45s ease-in-out;
}
.mobile-sidebar-container {
  position: fixed;
  background-color: white;
  width: 95%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  margin-left: -100%;
  visibility: hidden;
  transition: all 0.45s ease-in-out;
}
.content-of-mobile-sidebar-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.content-of-mobile-header-container {
  padding: 35px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-box-of-mobile-sidebar-container{
  width: 150px;
}
.menu-of-mobile-sidebar-container {
  position: relative;
  height: 100%;
  padding: 0 35px 35px;
}
.menu-of-mobile-sidebar-container .content-of-miraj-warehouse-menu-header {
  display: block;
}
.menu-of-mobile-sidebar-container .single-warehouse-menu-item {
  padding-right: 0;
  height: 50px;
}
.show-mobile-menu {
  display: none;
}
.menu-box-of-miraj-warehouse-header {
  position: relative;
  width: 100%;
  height: 100%;
}
/*===============================
# Responsive
=================================*/
@media screen and (max-width: 1600px){
  body{
    overflow-x: hidden;
  }
  .admin-panel-base-page-container .admin-panel-sidebar {
    margin-left: -100%;
  }
  .admin-header-container .menu-container-of-admin-header-container {
    display: none;
  }
  .admin-panel-main-content {
    padding-left: 2%;
    padding-right: 2%;
  }
  .admin-header-container {
    margin-top: 0;
  }
  .admin-panel-base-page-container.hide-sidebar .admin-panel-sidebar {
    margin-left: 0;
    width: 28%;
  }
  .admin-panel-base-page-container.hide-sidebar .mask-of-admin-panel-sidebar {
    opacity: .5;
    visibility: visible;
  }
  .left-side-of-card-builder-page-container {
    width: 35%;
  }
  .right-side-of-card-builder-page-container{
    width: 65%;
  }
}
@media screen and (max-width: 1024px){
  .data-displayer-pagination-container {
    padding-bottom: 100px !important;
}
.content-of-general-statistics-numbers-container {
  position: relative;
  display: block;
}
  .miraj-warehouse-footer .content-of-miraj-warehouse-menu-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .miraj-warehouse-footer .single-warehouse-menu-item {
    padding: 0;
    height: 35px;
}
  .show-mobile-menu {
    display: block;
  } 
  .menu-box-of-miraj-warehouse-header{
    display: none;
  }
  .container-of-show-mobile-menu {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
  }
  .user-profile-page-container .MuiGrid-root.MuiGrid-container {
    display: block !important;
  }
  .user-profile-page-container .MuiGrid-item {
    width: 100% !important;
    max-width: 100% !important;
  }
  .profile-main-content-container {
    padding-top: 25px;
  }
  .content-of-of-profile-main-content-container {
    padding: 0;
  }
  .all-the-cards-of-card-data-displayer-container .MuiGrid-item {
    max-width: 100% !important;
    width: 100% !important;
  }
  .all-the-cards-of-card-data-displayer-container .MuiGrid-container {
    display: block !important;
  }
  .admin-panel-base-page-container.hide-sidebar .admin-panel-sidebar {
    width: 90%;
  }
  .elements-of-form-elements-form-container .MuiGrid-item {
    width: 100% !important;
    max-width: 100% !important;
  }
  .elements-of-form-elements-form-container .MuiGrid-container {
    display: block !important;
  }
  .content-of-admin-panel-single-section-container {
    padding: 5%;
  }
  .single-item-details-page-boxes-container .MuiGrid-container {
    display: block !important;
  }
  .single-item-details-page-boxes-container .MuiGrid-item {
    width: 100% !important;
    max-width: 100% !important;
  }
  .login-form-container{
    max-width: 100% !important;
    width: 90% !important;
  }
}