/*===============================
# Fonts
=================================*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
/*===============================
# Scrollbar
=================================*/
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f05e2a;
  transition: all 0.35s ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #db5321;
}
/*===============================
# Base
=================================*/
body{
  font-family: "Roboto";
  /* font-family: "Prompt"; */
  margin: 0;
  width: 100%;
  height: 100%;
}
/*===============================
# Login Page
=================================*/
.login-form-container {
  max-width: 400px;
  border: 1px solid #00000017;
  border-radius: 5px;
  box-shadow: 0px 5px 50px #00000000;
}
.content-of-login-form-container{
  padding: 35px;
}
.sign-in-page-container{
  position: relative;
  width: 100%;
  height: 100vh;
}
.content-of-sign-in-page-container{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-logo-of-login-form-container img{
  max-width: 200px;
}
.content-of-app-logo-of-login-form-container{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.actual-form-of-login-form-container{
  padding-top: 35px;
}
/*===============================
# Page Preloader
=================================*/
.page-preloader-container.active{
    visibility: visible;
    opacity: 1;
}
.page-preloader-container{
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.45s ease-in-out;
}
.content-of-page-preloader-container{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*===============================
# Error Page
=================================*/
.error-page-container{
  position: relative;
  width: 100%;
  height: 100vh;
}
.content-of-error-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.icon-box-of-error-page-container svg{
  font-size: 45px;
  color: red;
}
.text-box-of-error-page-container{
  padding-top: 10px;
}
.text-box-of-error-page-container h1{
  font-size: 35px;
  text-align: center;
  color: red;
  margin: 0;
}
.text-box-of-error-page-container p {
  margin: 10px 0 0;
}
.buttons-of-error-page-container{
  padding-top: 35px;
}
.buttons-of-error-page-container .MuiButton-root{
  margin-right: 5px;
}
/*===============================
# Bottom Button FIxed
=================================*/
.add-new-item-bottom-container a {
  text-decoration: none;
  margin-right: 5px;
}
.add-new-item-bottom-container {
  position: fixed;
  bottom: 35px;
  right: 35px;
}
/*===============================
# Item Details
=================================*/
.title-box-of-admin-panel-single-section-container h3{
  margin: 0;
  font-size: 24px;
}
.title-box-of-admin-panel-single-section-container{
  padding-bottom: 30px;
}
.thumb-box-of-single-item-details-page-boxes-container{
  position: relative;
  width: 100%;
}
.thumb-box-of-single-item-details-page-boxes-container img {
  width: 100%;
}
.img6-5cm-container-of-stickers-page-item {
  max-width: 6.5cm;
}
.content-of-img6-5cm-container-of-stickers-page-item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.img6-5cm-container-of-stickers-page-item{
  margin-top: -1px;
}
/*===============================
# User groups
=================================*/
.warehouse-access-container {
  border: 1px solid #00000017;
  border-radius: 5px;
  position: relative;
}
.content-of-warehouse-access-container {
  position: relative;
  padding: 35px;
}
.title-box-of-warehouse-access-container h3{ 
  margin: 0;
}
.single-accessed-warehouse-box {
  border: 1px solid #00000017;
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;
}
.title-box-of-single-accessed-warehouse-box{
  margin: 0;
  border-bottom: 1px solid #00000017;
  height: 50px;
}
.title-box-of-single-accessed-warehouse-box h3{
  line-height: 50px;
  margin: 0;
  padding-left: 10px;
}
.all-the-warehouses-accessed-container {
  padding-top: 20px;
}
.single-section-of-accessed-warehouse-box {
  margin: 0;
  border-bottom: 1px solid #00000017;
  height: 50px;
}
.single-section-of-accessed-warehouse-box:last-child{
  border-bottom: 0;
}
.content-of-single-section-of-accessed-warehouse-box {
  position: relative;
  padding-left: 10px;
}
.single-section-of-accessed-warehouse-box .MuiFormControlLabel-root{
  margin-top: 3px;
}
/*===============================
# Set password form
=================================*/
.title-box-of-login-form-container h3{
  margin: 0;
}
.title-box-of-login-form-container{
  padding-top: 20px;
}
/*===============================
# QR Code Scanner
=================================*/
.qr-code-scanner-page-container{
  position: relative;
  width: 100%;
  height: 100%;
}
/*===============================
# Where is item
=================================*/
.where-is-item-single-box {
  border: 1px solid #00000017;
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 5px;
}
.message-box-of-admin-where-is-item-box-container {
  margin-bottom: 15px;
}
.where-is-item-single-box h4{
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.where-is-item-single-box h3{
  font-weight: bold;
  margin: 5px 0 0;
}
.content-of-where-is-item-single-box {
  padding: 35px;
}
/*===============================
# Profile Page
=================================*/
.user-profile-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  /* max-width: 75%; */
  /* padding-top: 50px; */
}
.profile-menu-container-wrapper {
  padding-top: 20px;
}
.profile-menu-container-wrapper .admin-panel-menu-navigation {
  padding: 0;
}
.head-box-of-profile-main-content-container h2{
  margin: 0;
}
.head-box-of-profile-main-content-container{
  margin-bottom: 35px;
}
/* .profile-page-content-component-container{
  padding-top: 35px;
} */
.content-of-of-profile-main-content-container{
  padding-left: 15px;
}
.profile-item-transfer-requests-section-container {
  padding-bottom: 50px;
}
.back-to-profile-section-page-box button {
  margin: -2px 5px 0 0;
}
.back-to-profile-section-page-box {
  padding-bottom: 20px;
}
.back-to-profile-section-page-box h3 {
  margin: 0;
}
.back-to-profile-section-page-box a {
  color: black;
  text-decoration: none;
}
/*===============================
# Home
=================================*/
.logo-box-of-miraj-warehouse-header img{
  max-width: 150px;
}
.miraj-warehouse-header {
  position: relative;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #80808038;
  height: 125px;
}
.content-of-miraj-warehouse-header {
  position: relative;
  width: 100%;
  max-width: 95%;
  height: 100%;
  margin: 0 auto;
}
.miraj-warehouse-header .MuiGrid-container {
  position: relative;
  height: 100%;
}
.logo-box-of-miraj-warehouse-header {
  position: relative;
  height: 100%;
  width: 100%;
}
.content-of-logo-box-of-miraj-warehouse-header {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.miraj-warehouse-menu-header {
  position: relative;
  width: 100%;
  height: 100%;
}
.content-of-miraj-warehouse-menu-header {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  justify-content: flex-end;
}
.single-warehouse-menu-item a {
  text-decoration: none;
  color: black;
  font-weight: 500;
  transition: all 0.35s ease-in-out;
}
.single-warehouse-menu-item a:hover,
.single-warehouse-menu-item a:focus{
  color: #f05e2a;
}
.single-warehouse-menu-item {
  padding-right: 30px;
}
.miraj-warehouse-footer {
  background-color: #ececec;
}
.main-page-content-container{
  padding-top: 80px;
  padding-bottom: 80px;
}
.content-of-main-page-content-container {
  position: relative;
  width: 100%;
  max-width: 95%;
  height: 100%;
  margin: 0 auto;
}
.miraj-warehouse-title-page-section h1{
  margin: 0;
}
.miraj-warehouse-title-page-section {
  padding-bottom: 30px;
}
/*===============================
# Header, footer
=================================*/
.warehouse-main-page-wrapper{
  min-height: calc(100vh - 250px);
}
.miraj-warehouse-footer  {
  width: 100%;
  /* height: 300px;             */
  position: relative;
  min-height: 300px;
  box-sizing: border-box;
}
.content-of-miraj-warehouse-footer{
  position: relative;
  width: 100%;
  max-width: 95%;
  height: 100%;
  margin: 0 auto;
  padding: 35px 0;
}
.logo-box-of-info-box-of-miraj-warehouse-footer img {
  max-width: 150px;
}
.info-box-of-miraj-warehouse-footer{
  position: relative;
  width: 100%;
  height: 100%;
}
.content-of-info-box-of-miraj-warehouse-footer {
  position: relative;
  width: 100%;
  height: 100%;
}
.logo-box-of-info-box-of-miraj-warehouse-footer {
  padding: 155px 0 0;
}
.menu-of-miraj-warehouse-footer {
  position: relative;
  width: 100%;
  height: 100%;
}
.content-of-menu-of-miraj-warehouse-footer {
  position: relative;
  width: 100%;
  height: 100%;
}
.menu-of-miraj-warehouse-footer .content-of-miraj-warehouse-menu-header {
  align-items: flex-end;
}
/*===============================
# Card Data Displyer
=================================*/
.card-data-displayer-message-container {
  padding-top: 15px;
}
.title-box-of-card-data-displayer-container h3 {
  margin: 0;
  font-size: 25px;
}
.all-the-cards-of-card-data-displayer-container {
  padding-top: 35px;
}
.content-of-all-the-cards-of-card-data-displayer-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.single-card-data-displayer-box {
  position: relative;
  width: 100%;
  height: 600px;
  border-radius: 10px;
  overflow: hidden;
}
.content-of-single-card-data-displayer-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.thumb-box-of-single-card-data-displayer-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.mask-box-of-single-card-data-displayer-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  background-color: #00000063;
}
.content-of-thumb-box-of-single-card-data-displayer-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.thumb-img-card-data-displyer {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.text-box-of-mask-box-of-single-card-data-displayer-box {
  position: absolute;
  bottom: 35px;
  width: 100%;
  color: white;
}
.text-box-of-mask-box-of-single-card-data-displayer-box h3{
  margin: 0;
}
.text-box-of-mask-box-of-single-card-data-displayer-box p {
  margin: 10px 0 0;
}
.content-of-text-box-of-mask-box-of-single-card-data-displayer-box {
  padding: 0 35px 0;
}
/*===============================
# Single Card
=================================*/
.single-business-card-page-container {
  /* position: fixed; */
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f2f2;
  overflow-y: scroll;
  background-position: center;
    /* background-size: cover; */
    min-height: 100vh;
}
.content-of-single-business-card-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 35px;
}
.img-thumbnail-box-of-header-box-of-single-business-card-container img {
  max-width: 100%;
  background-color: #f0f0f0;
}
.content-of-img-thumbnail-box-of-header-box-of-single-business-card-container{
  position: relative;
  width: 100%;
  height: 100%;
}
.single-business-card-container{
  position: relative;
  max-width: 450px;
  min-width: 450px;
  width: 100%;
}
.content-of-single-business-card-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 35px 0;
  flex-direction: row;
  align-items: center;
  flex-direction: column;
}
.single-business-card-container {
  position: relative;
  max-width: 450px;
  background-color: white;
  overflow: hidden;
  border-radius: 25px;
  box-shadow: 0px 6px 30px #0000000f;
}
.header-box-of-single-business-card-container {
  background: #4f7bf7;
  inset: 0px;
  clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 7rem), 0px 100%);
  content: "";
  position: absolute;
}
.header-box-of-single-business-card-container {
  position: relative;
  height: 300px;
}
.card-profile-details-box  {
  color: white;
}
.card-profile-details-box h3 {
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 400;
  padding-top: 25px;
}
.card-profile-details-box h2{
  margin: 0;
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 2.25rem;
}
.card-profile-details-box p {
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 400;
}
.content-of-card-profile-details-box {
  position: relative;
  padding: 35px;
}
.img-thumbnail-box-of-header-box-of-single-business-card-container {
  position: absolute;
  bottom: 0;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 5px solid white;
  background-color: rgb(241 241 241);
  overflow: hidden;
  right: 25px;
  top: 140px;
}
.headline-box-of-single-business-card-container{
  padding-top: 15px;
}
.content-of-headline-box-of-single-business-card-container {
  padding: 0 35px;
}
.content-of-icon-box-of-single-card-link-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-box-of-single-card-link-box {
  position: relative;
  width: 50px;
  height: 50px;
  background: #4f7bf7;
  border-radius: 50px;
  color: white;
}
.text-box-of-single-card-link-box h3 {
  font-size: 1.1rem;
  line-height: 1.5em;
  margin: 0px;
  font-weight: 500;
  margin: 0;
}
.text-box-of-single-card-link-box h4 {
  margin: 2px 0 0;
  font-weight: 400;
  color: #0000007a;
}
.content-of-single-card-link-box {
  padding: 15px 35px;
}
.text-box-of-single-card-link-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.content-of-text-box-of-single-card-link-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.card-links-container {
  padding: 15px 0 0;
}
.single-card-link-box {
  position: relative;
  transition: all 0.35s ease-in-out;
}
.single-card-link-box:hover,
.single-card-link-box:focus {
  background-color: #0000000a;
  cursor: pointer;
}
.google-maps-link-box{
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.add-to-contacts-card-button {
  position: relative;
  padding: 20px 0 0;
}
.add-to-contacts-card-button button {
  color: white;
  border: none;
  width: 100%;
  border-radius: 50px;
  padding: 0 30px;
  transition: all 0.35s ease-in-out;
}
.add-to-contacts-card-button button:hover, .add-to-contacts-card-button button:focus{
  border: none !important;
  opacity: .8;
}
/*===============================
# Card Builder
=================================*/
.head-box-of-card-builder-page-container {
  position: relative;
  border-bottom: 1px solid #00000017;
}
.head-box-of-card-builder-page-container h3{
  margin: 0 0 0 5px;
}
.content-of-head-box-of-card-builder-page-container {
  position: relative;
  padding: 15px 35px;
  display: flex;
  align-content: center;
  align-items: center;
}
.icon-box-of-single-card-link-box svg path,
.icon-box-of-single-card-link-box svg path#Path {
  fill: white;
}
.content-of-card-builder-form-container {
  position: relative;
  padding: 35px;
}
.content-of-card-builder-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}
.card-builder-page-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.content-of-right-side-of-card-builder-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.left-side-of-card-builder-page-container {
  position: relative;
  width: 20%;
  height: 100%;
  overflow-y: scroll;
  border-right: 1px solid #00000017;
}
.right-side-of-card-builder-page-container {
  position: relative;
  width: 80%;
  height: 100%;
}
.actual-card-of-card-builder-page-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.card-builder-page-container .content-of-actual-card-of-card-builder-page-container{
  display: block;
}
.card-builder-page-container .single-business-card-container {
  margin: 0 auto;
}
.content-of-actual-card-of-card-builder-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
/*   align-items: flex-start; */
  align-items: center;
  justify-content: center;
  padding: 35px 0;
}
.card-builder-title-box h3{
  margin: 0;
}
.card-builder-title-box{
  padding-bottom: 10px;
}
.card-links-builder-container .card-builder-title-box {
  padding: 35px 0;
}
.content-of-additional-links-modal-boxes {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.link-type-additional-box {
  width: 100px;
  height: 100px;
  padding: 5px;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.35s ease-in-out;
}
.link-type-additional-box:hover, .link-type-additional-box:focus{
  background-color: #0000000d;
}
.content-of-link-type-additional-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-of-link-type-additional-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.link-type-additional-box h3 {
  font-weight: 400;
  margin: 5px 0 0;
  font-size: 15px;
}
.content-of-single-builder-link-box {
  padding: 35px;
}
.single-builder-link-box {
  border: 1px solid #00000017;
  background-color: white;
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}
.content-of-drag-icon-box-of-single-builder-link-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.drag-icon-box-of-single-builder-link-box {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: grab;
}
.create-or-update-container-of-card-builder-page-container {
  position: relative;
  padding-top: 20px;
}
.request-response-message-container {
  padding: 15px 0 0;
}
.loading-box-of-single-builder-link-box.active{
  opacity: 1;
  visibility: visible;
}
.loading-box-of-single-builder-link-box {
  position: absolute;
  background-color: #ffffffcc;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease-in-out;
}
.content-of-loading-box-of-single-builder-link-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-of-title-box-of-single-card-link-box {
  padding: 3px;
}
.title-box-of-single-card-link-box {
  margin: 0 35px;
  text-align: center;
  color: white;
  border-radius: 10px;
  margin-top: 10px;
}
/*===============================
# Card Template 02
=================================*/
.avatar-box-of-card-template-02-header-box{
  position: relative;
  width: 100%;
  height: 100%;
}
.content-of-avatar-box-of-card-template-02-header-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.content-of-card-template-02-header-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.card-template-02-header-box {
  clip-path: circle(100% at 50% 0%);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  height: 450px;
}
.thumb-of-avatar-box-of-card-template-02-header-box {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
.card-template-02-user-info-box {
  position: relative;
  width: 70%;
  margin: -86px auto 0;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px, rgb(0 0 0 / 6%) 0px 1px 2px;
}
.card-template-02-user-info-box h3{
  margin: 0;
  font-size: 1.75rem;
  font-weight: 800;
  line-height: 2.25rem;
}
.card-template-02-user-info-box h4 {
  margin: 2px 0 0;
  font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5rem;
    white-space: pre-wrap;
}
.card-template-02-user-info-box p {
  margin: 0;
  font-size: 1.1rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.5rem;
}
.card-template-02-user-info-box {
  text-align: center;
}
.content-of-card-template-02-user-info-box {
  position: relative;
  padding: 25px;
}
/* .single-business-card-container.card-template-02 .content-of-card-links-container {
  background-color: #0000000a;
} */
.content-of-all-card-templates-boxes {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.single-card-template-box.active {
  background-color: #2406F9;
  color: white;
  border: 2px solid #2406F9;
}
.single-card-template-box.active:hover, .single-card-template-box.active:focus{
  background-color: #1701B7;
  border: 2px solid #1701B7;
  color: white;
}
.single-card-template-box h3 {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.25s ease-in-out;
}
.single-card-template-box {
  position: relative;
  border: 2px solid #0000002e;
  border-radius: 5px;
  padding: 15px;
  margin-right: 5px;
  color: #00000070;
  transition: all 0.25s ease-in-out;
}
.single-card-template-box:hover, .single-card-template-box:focus {
  border: 2px solid #0000008c;
  border: 2px solid #0000004a;
  background-color: #0000001a;
  color: #00000091;
  cursor: pointer;
}
.all-card-templates-boxes {
  position: relative;
  padding: 5px 0 0;
}
.select-template-container {
  padding-top: 40px;
}
.single-business-card-container a {
  color: inherit;
  text-decoration: none;
}
img.single-card-company-logo-img {
  max-width: 150px;
  padding-bottom: 5px;
}
.single-card-link-box iframe {
  border: none;
  width: 100%;
  height: 210px;
}
.emdebed-video-of-single-card-link-box h3 {
  margin: 0 0 0;
  font-size: 1.1rem;
  line-height: 1.5em;
  font-weight: 600;
  margin: 0;
}
.iframe-of-emdebed-video-of-single-card-link-box {
  padding-top: 10px;
}
.all-the-links-of-card-links-builder-container ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

/*===============================
# Folders
=================================*/
.folders-list-container .MuiAccordion-root {
  position: relative;
  background-color: white;
  border: 1px solid #00000017;
  border-radius: 5px;
  box-shadow: none !important;
  margin-bottom: 10px;
}
.folders-list-container .MuiAccordion-root:before{
  background-color: transparent;
}
.single-folder-chip-box {
  position: relative;
  background-color: white;
  border: 1px solid #00000017;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.folder-chips-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.content-of-info-boxes-of-single-folder-chip-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -10px;
}
.single-info-box-of-folder-chip h3{
  margin: 3px 0 0;
}
.single-info-box-of-folder-chip h4 {
  margin: 0;
    font-weight: 400;
    font-size: 15px;
}
.content-of-single-folder-chip-box {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 35px;
  box-sizing: border-box;
  overflow-x: scroll;
}
.content-of-loading-chip-details-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-chip-details-box.active{
  visibility: visible;
  opacity: 1;
}
.loading-chip-details-box {
  position: absolute;
  background-color: #ffffffbd;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease-in-out;
}
.content-of-load-more-item-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.load-more-item-container {
  position: relative;
  padding: 10px 0;
}
.load-more-item-container button {
  border-radius: 50px;
  box-shadow: none;
}
.content-of-folder-header-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.edit-folder-name-icon-button svg {
  width: 18px;
  height: 18px;
}
.edit-folder-name-icon-button {
  margin: -4px 0 0 2px;
}
.edit-folder-input-box {
  position: absolute;
  z-index: 3;
  background-color: white;
  width: 300px;
  top: -4px;
  left: 40px;
}
.folders-list-container .Mui-focusVisible{
  background-color: transparent !important;
}
/*===============================
# Chip View Page
=================================*/
.preloader-of-view-single-chip-page-container.active{
  opacity: 1;
  visibility: visible;
}
.preloader-of-view-single-chip-page-container {
  position: fixed;
  z-index: 9999;
  background-color: white;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease-in-out;
}
.content-of-preloader-of-view-single-chip-page-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
/*===============================
# Slider
=================================*/
.single-slider-in-digital-business-card-box .each-slide-effect > div {
  height: 210px;
}
.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 500px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.slider-editor-container {
  position: relative;
  width: 50%;
  height: 100%;
}
.single-slider-container{
  position: relative;
  width: 100%;
  height: 100%;
}
.single-slider-container button.nav.default-nav {
  margin-left: 15px;
  margin-right: 15px;
}
.single-slider-container button.nav.default-nav {
  margin-left: 15px;
  margin-right: 15px;
  background: #ffffff00;
  fill: #ffffffa1;
  transition: all 0.35s ease-in-out;
}
.single-slider-container button.nav.default-nav:hover,
.single-slider-container button.nav.default-nav:focus {
  background: #ffffff30;
  fill: #ffffff;
}
.mask-of-single-slider-container {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(356deg, rgb(0 0 0 / 63%) 0%, rgba(255,255,255,0) 100%);
  color: white;
}
.mask-of-single-slider-container h3 {
  margin: 0;
  font-size: 24px;
}
.content-of-mask-of-single-slider-container {
  position: absolute;
  bottom: 35px;
  padding: 0 35px;
}
.slider-editor-container .single-slider-container {
  margin-bottom: 35px;
}
.content-of-slider-options-container {
  padding: 35px;
}
.slider-options-container {
  border: 1px solid #00000017;
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 100%;
}
.slides-of-slider-options-container ul {
  margin: 0;
  padding: 0;
}
.slides-of-slider-options-container li{
  list-style: none;
}
.single-editable-slide-box{
  border: 1px solid #00000017;
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  background-color: white;
}
.content-of-single-editable-slide-box{
  padding: 35px;
}
.slides-of-slider-options-container {
  padding: 35px 0 0;
}
.slides-editor-title {
  margin: 0 0 10px;
}
.single-editable-slide-box .form-element-image-uploader-container {
  border: none;
}
.single-editable-slide-box .content-of-form-element-image-uploader-container {
  padding: 0;
}
.buttons-box-of-single-editable-slide-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.action-buttons-of-slider-editor-container{
  padding-top: 30px;
}
/* .content-of-single-slider-in-digital-business-card-box{
  padding: 0 35px;
} */
.single-slider-in-digital-business-card-box .mask-of-single-slider-container {
  display: none;
}
.title-box-of-slider-card-container h3 {
  margin: 0;
  text-align: center;
  color: white;
}
.title-box-of-slider-card-container {
  padding: 15px;
}
.slider-card-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
/*===============================
# Add new Link
=================================*/
.add-new-link-box {
  border: 1px solid #00000017;
  background-color: white;
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 100%;
  color: #4b4b4b;
  transition: all 0.35s ease-in-out;
}
.add-new-link-box:hover, .add-new-link-box:focus{
  cursor: pointer;
  background-color: #f6f6f6;
}
.add-new-link-box h3{
  margin: 0 0 0 5px;
}
.content-of-add-new-link-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px;
}
/*===============================
# Image Link
=================================*/
.content-of-single-card-thumbnail-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.single-card-thumbnail-box {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.img-box-of-single-card-thumbnail-box {
  position: relative;
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center;
}
.content-of-editor-for-single-card-link-box {
  padding: 15px 35px;
}
.editor-for-single-card-link-box img {
  max-width: 100%;
}
/*===============================
# Two Factor Auth
=================================*/
.icon-box-of-two-factor-auth-head-box{
  position: relative;
  background-color: #2406F9;
  width: 50px;
  height: 50px;
  color: white;
  border-radius: 50px;
}
.content-of-icon-box-of-two-factor-auth-head-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-of-two-factor-auth-head-box {
  position: relative;
  width: 100%;
  height: 100%;
  /* display: flex; */
}
.two-factor-auth-head-box h3{
  margin: 0;
}
.two-factor-auth-head-box p{
  margin: 10px 0 0;
}
.text-box-of-two-factor-auth-head-box {
  margin-left: 10px;
}
.set-up-two-factor-auth-page .content-of-text-box-of-two-factor-auth-head-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.set-up-two-factor-auth-page .text-box-of-two-factor-auth-head-box {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
}
p.two-factor-auth-description {
  margin: 0;
  line-height: 1.4;
}
.two-factor-auth-qr-code-container img {
  max-width: 150px;
}
.two-factor-auth-qr-code-container {
  border: 1px solid #00000017;
  border-radius: 5px;
  margin: 30px 0 0;
}
.content-of-two-factor-auth-qr-code-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px;
}
/*===============================
# Block Account Page
=================================*/
.info-box-of-block-account-page-container.error {
  background-color: #dc000021;
  color: #dc0000;
}
.icon-box-of-info-box-of-block-account-page-container{
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.info-box-of-block-account-page-container.error .icon-box-of-info-box-of-block-account-page-container{
  background-color: #dc000029;
}
.info-box-of-block-account-page-container.success {
  background-color: #5f8d4e24;
  color: #5F8D4E;
}
.info-box-of-block-account-page-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  border-radius: 15px;
  text-align: center;
}
.text-box-of-info-box-of-block-account-page-container h2{
  margin: 0;
}
.text-box-of-info-box-of-block-account-page-container p {
  margin: 10px 0 0;
  line-height: 1.5;
}
.content-of-icon-box-of-info-box-of-block-account-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-of-info-box-of-block-account-page-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px;
}
.text-box-of-info-box-of-block-account-page-container {
  padding: 10px 0 0;
}
.block-account-page-container {
  position: relative;
  width: 100%;
  height: 100vh;
}
.content-of-block-account-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-box-of-block-account-page-container.success .icon-box-of-info-box-of-block-account-page-container {
  background-color: #5f8d4e24;
}

/*===============================
# Two Factor Auth
=================================*/
.two-factor-auth-code-field-container {
  background-color: white;
  border: 1px solid #00000017;
  border-radius: 5px;
}
.content-of-two-factor-auth-code-field-container {
  padding: 35px;
}
.two-factor-auth-code-field-container .content-of-two-factor-auth-head-box {
  display: flex;
}
.reset-password-form .icon-box-of-two-factor-auth-head-box {
  display: none;
}
/*===============================
# Face Detection
=================================*/
.face-detector-page-container {
  position: relative;
  width: 100%;
  height: 100vh;
}
.content-of-face-detector-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.camera-container-face-detector-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.content-of-camera-container-face-detector-box {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
}
.face-detector-preloader-container.active {
  opacity: 1;
  visibility: visible;
}
.face-detector-preloader-container {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease-in-out;
}
.content-of-face-detector-preloader-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*===============================
# Miraj ID Card
=================================*/
.miraj-logo-of-miraj-labour-card-container-box img {
  width: 200px;
}
.miraj-labour-card-container-box {
  position: relative;
  border: 2px solid #cbcbcb;
  border-radius: 25px;
  width: 700px;
}
.content-of-miraj-labour-card-container-box {
  position: relative;
  padding: 35px;
  display: flex;
}
.left-side-of-miraj-labour-card-container-box {
  margin-right: 30px;
}
.labour-single-text-data-box h3 {
  margin: 5px 0 0;
}
.labour-single-text-data-box h4 {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.content-of-bottom-of-miraj-labour-card-container-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom-of-miraj-labour-card-container-box {
  padding: 25px 0 0;
  margin: 25px 0 0;
  border-top: 2px solid #e9e9e9;
}
.header-box-of-miraj-labour-card-container-box span.id-number-on-miraj-card {
  font-weight: 400;
}
.buttons-box-of-miraj-id-card-container-of-registration-counter {
  padding-top: 10px;
}
.header-box-of-miraj-labour-card-container-box h3 {
  margin: 0 0 15px;
}
.header-box-of-miraj-labour-card-container-box {
  border-bottom: 2px solid #e9e9e9;
  margin-bottom: 20px;
}
.content-of-preloader-of-request-loading-of-face-detector {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.content-of-request-loading-of-face-detector {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.content-of-camera-container-face-detector-box .request-loading-of-face-detector {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.text-box-of-request-loading-of-face-detector p {
  margin: 15px 0 0;
}
.text-box-of-request-loading-of-face-detector h3 {
  margin: 0;
}
.text-box-of-request-loading-of-face-detector {
  text-align: center;
  padding: 35px 0 0;
}

/*===============================
# Crop Dialog
=================================*/
.buttons-box-of-image-cropper-dilog-box {
  position: fixed;
  width: 100%;
  height: 75px;
  z-index: 999;
  bottom: 0;
  background-color: white;
}
.content-of-buttons-box-of-image-cropper-dilog-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-cropper-dilog-box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: white;
}
.content-of-image-cropper-dilog-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.image-uploader-cropper-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.image-cropper-dilog-box .reactEasyCrop_Container {
  width: 500px !important;
  margin: 0 auto;
  /* height: 100%; */
}
/*===============================
# Labour Single Page
=================================*/
.passport-or-id-labour-photo-container img {
  max-width: 700px;
  width: 100%;
}
.passport-or-id-labour-photo-container {
  max-width: 800px;
  width: 100%;
}
.preloader-of-labour-assigned-form-container.active {
  opacity: 1;
  visibility: visible;
}
.content-of-preloader-of-labour-assigned-form-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preloader-of-labour-assigned-form-container {
  position: absolute;
  background-color: #ffffffd4;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease-in-out;
}
.cancel-to-delete-button:hover, .cancel-to-delete-button:focus {
  background-color: #b33737 !important;
  box-shadow: none !important;
}
.cancel-to-delete-button {
  background-color: #ea5455 !important;
  box-shadow: none !important;
  margin-right: 5px !important;
}
.sure-to-delete-button:hover, .sure-to-delete-button:focus {
  background-color: #598454 !important;
  box-shadow: none !important;
}
.sure-to-delete-button {
  background-color: #7AA874 !important;
  box-shadow: none !important;
}
.buttons-container-of-data-displayer-single-item .MuiCircularProgress-root {
  width: 30px !important;
  height: 30px !important;
  color: #757575;
}
/*===============================
# Report
=================================*/
.labour-check-in-report-hidden {
  width: 1994px;
  position: fixed;
  z-index: 9999;
  background-color: white;
  left: 0;
  right: 0;
  top: 0;
  margin-top: -10000000000000000%;
}
.content-of-check-in-report-container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 35px;
}
.wrapper-of-check-in-report-container{
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
}
.check-in-report-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.logo-of-header-of-check-in-report-container img {
  width: 200px;
}
.title-of-header-of-check-in-report-container h2{
  font-size: 32px;
  margin: 0;
  letter-spacing: 1.5px;
}
.content-of-header-of-check-in-report-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.check-in-report-info-box-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 35px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
}
.left-of-single-info-box-of-check-in-report h3 {
  margin: 0;
}
.right-of-single-info-box-of-check-in-report h4{
  margin: 0;
  font-weight: 400;
}
.content-of-single-info-box-of-check-in-report {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
.left-of-single-info-box-of-check-in-report {
  width: 10%;
  border-right: 1px solid #e9e9e9;
}
.content-of-left-of-single-info-box-of-check-in-report {
  padding: 15px;
}
.content-of-right-of-single-info-box-of-check-in-report {
  padding: 15px;
}
.single-info-box-of-check-in-report {
  border-bottom: 1px solid #e9e9e9;
}
.single-info-box-of-check-in-report:last-child{
  border: none;
}
.miraj-id-card-box-of-single-labour-report-container {
  padding-top: 30px;
}
.content-of-single-labour-report-container {
  padding: 35px;
}
.single-labour-report-container {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  margin-bottom: 10px;
}
.single-labour-report-container .check-in-report-info-box-container {
  margin-top: 0;
}
.all-labour-in-timesheet-report-container {
  padding-top: 35px;
}
.labour-id-card-img-box {
  padding-left: 15px;
}
.labour-id-card-img-box img {
  max-width: 600px;
  width: 100%;
}
.content-of-grid-data-of-single-labour-report-container {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
}
.report-summary-box {
  border-top: 1px solid #e9e9e9;
  margin-top: 30px;
  padding-top: 10px;
}
.report-summary-box .check-in-report-info-box-container{
  margin-top: 10px;
}
.save-report-action-button-container{
  padding-top: 15px;
}
.wrapper-of-miraj-labour-card-container-box {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-x: scroll;
}
.miraj-logo-of-miraj-labour-card-container-box svg {
  width: 200px;
}
.content-of-sign-box-of-check-in-report-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.sign-box-of-check-in-report-container h3 {
  margin: 0;
  padding: 0 50px;
}
.sign-box-of-check-in-report-container {
  padding-top: 100px;
  padding-bottom: 100px;
}
/*===============================
# Registration Counter Page
=================================*/
.single-registration-counter-page{
  position: relative;
  width: 100%;
  height: 100vh;
}
.content-of-single-registration-counter-page{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-of-select-regitsration-type-select-box {
  padding: 35px;
}
.select-regitsration-type-select-box {
  border: 1px solid #00000017;
  border-radius: 5px;
  width: 100%;
  max-width: 600px;
}
.select-regitsration-type-select-box h3 {
  margin: 0;
  text-align: center;
}
.content-of-buttons-box-of-select-regitsration-type-select-box {
  position: relative;
  display: flex;
}
.buttons-box-of-select-regitsration-type-select-box button {
  width: 100%;
}
.buttons-box-of-select-regitsration-type-select-box {
  padding-top: 25px;
}
.buttons-box-of-select-regitsration-type-select-box button.check-in-button {
  background-color: #7AA874;
  color: white;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: all 0.35s ease-in-out;
}
.buttons-box-of-select-regitsration-type-select-box button.check-in-button:hover,
.buttons-box-of-select-regitsration-type-select-box button.check-in-button:focus{
  background-color: #547b4f;
}
.buttons-box-of-select-regitsration-type-select-box button.check-out-button {
  background-color: #DD5353;
  color: white;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: all 0.35s ease-in-out;
}
.buttons-box-of-select-regitsration-type-select-box button.check-out-button:hover,
.buttons-box-of-select-regitsration-type-select-box button.check-out-button:focus{
  background-color: #ab3030;
}
/*===============================
# Load more items
=================================*/
.load-more-items-container {
  padding-top: 15px;
}
.content-of-load-more-items-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*===============================
# Check in details modal
=================================*/
.single-check-in-details-container .where-is-item-single-box {
  border: none;
  border-bottom: 1px solid #00000017;
  margin-bottom: 0;
  border-radius: 0;
}
.single-check-in-details-container .content-of-where-is-item-single-box {
  padding: 15px 0;
}
.check-in-photo-box {
  padding-top: 10px;
}
/*===============================
# Select Location Field
=================================*/
.select-many-locations-container {
  position: relative;
  background-color: white;
  border: 1px solid #00000017;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.content-of-select-many-locations-container {
  padding: 35px;
}
.title-box-of-select-many-locations-container h3{
  margin: 0;
}
.items-box-of-select-many-locations-container {
  padding-top: 15px;
}
/*===============================
# General Stats
=================================*/
.chart-box-general-statics {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 450px;
  box-sizing: border-box;
}
/*===============================
# 
=================================*/

/*===============================
# 
=================================*/

/*===============================
# 
=================================*/

/*===============================
# Responsive
=================================*/
@media screen and (max-width: 500px){
  .single-business-card-container {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }
  .content-of-single-business-card-page-container {
    padding: 35px 15px;
    box-sizing: border-box;
}
  .card-template-02-user-info-box{
    width: 80%;
  }
  .text-box-of-single-card-link-box {
    padding-left: 10px;
  }
  .header-box-of-single-business-card-container {
    height: 430px !important;
  }
  .img-thumbnail-box-of-header-box-of-single-business-card-container{
    top: 280px !important;
  }
}