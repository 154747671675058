/*
* Form Elements Styles
* @author: Maxim Starovoitov
*/
/*===============================
# Form Element Container
=================================*/
.single-form-element-container{
    position: relative;
    width: 100%;
    height: 100%;
    /* margin-bottom: 10px; */
}
.content-of-elements-of-form-elements-form-container .MuiGrid-item {
    margin-bottom: 15px;
}
.content-of-single-form-element-container{
    position: relative;
    width: 100%;
    height: 100%;
}
.content-of-elements-of-form-elements-form-container .MuiGrid-item {
    padding-right: 5px;
}
/*=========================
# Loading Element
==========================*/
.loading-form-element-skeleon-box {
    position: relative;
    width: 100%;
    height: 50px;
    border: 1px solid #bdbdbd;
    border-radius: 3px;
    display: block;
}
.loading-form-element-skeleon-box .MuiSkeleton-root{
    position: relative;
    width: 100%;
    height: 50px;
}
.loading-form-element-skeleon-box .fake-label{
    position: absolute;
    font-size: 11px;
    background-color: white;
    top: -10px;
    left: 5px;
    /* z-index: 9; */
    padding: 5px;
}
.loading-form-element-skeleon-box .fake-placeholder{
    position: absolute;
    top: 17px;
    left: 10px;
}
/*===============================
# Button
=================================*/
.MuiButton-root{
    min-height: 50px;
}
/*===============================
# Message Box
=================================*/
.message-box-container {
    position: relative;
    background-color: #0000000d;
    border-radius: 15px;
}
.content-of-message-box-container {
    position: relative;
    padding: 35px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}
.message-box-container h3{
    margin: 0;
}
.message-box-container p {
    margin: 5px 0 0;
}
.text-box-of-message-box-container {
    padding-left: 15px;
}
.message-box-container.error {
    background-color: #b2000012;
    color: red;
}
.icon-box-of-message-box-container svg{
    font-size: 32px !important;
}
.message-box-container.warning {
    background-color: #feb1391f;
    color: #fcaf36;
}
.message-box-container.info {
    color: #0078AA;
    background-color: #0078aa1a;
}
.message-box-container.success {
    color: #5BB318;
    background-color: #5bb31829;
}
.message-box-of-form-element-image-uploader-container {
    padding-top: 15px;
}
.message-box-container.info a {
    color: #0078AA;
}
.message-box-container.message-box-container.warning a {
    color: #fcaf36;
}
.message-box-container.message-box-container.success a {
    color: #5BB318;
}
.message-box-container.message-box-container.error a {
    color: red;
}
.text-box-of-message-box-container a{
    text-decoration: none;
    font-weight: bold;
}
/*===============================
# Image Uploader
=================================*/
.form-element-image-uploader-container {
    border: 1px solid #00000017;
    border-radius: 5px;
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 15px;
}
.content-of-form-element-image-uploader-container {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 35px;
}
.text-box-of-form-element-image-uploader-container h3{
    margin: 0;
}
.text-box-of-form-element-image-uploader-container p{
    margin: 0 0 0;
    padding-left: 45px;
    font-size: 15px;
}
.image-box-of-form-element-image-uploader-container {
    padding: 35px 0 0;
}
.image-box-of-form-element-image-uploader-container img{
    width: 100%;
    max-width: 500px;
}
.buttons-container-of-form-element-image-uploader-container button{
    margin-right: 5px;
}
.buttons-container-of-form-element-image-uploader-container{
    padding-top: 15px;
}
/*===============================
# Link
=================================*/
.single-form-element-container a{
    text-decoration: none;
    color: #1976d2;
}
/*===============================
# Disbaled Container
=================================*/
.single-form-element-container.disabled {
    pointer-events: none;
    opacity: .5;
}
/*===============================
# Currency Field
=================================*/
.currency-field {
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    position: relative;
    width: 100%;
    height: 100%;
    height:  56px;
    padding: 0 10px;
    box-sizing: border-box;
    font-family: 'Roboto';
}
/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/